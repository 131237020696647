.dashboard-page {

  .dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    h2{
      color: #1C1C1C;
      margin-bottom: 0;
    }
  }
  .dashboard-widget-container,
  .user-chart-container,
  .revenue-container {
    margin-bottom: 20px;
  }
  .widget-card {
    display: flex;
    padding: 1.5625rem;
    background: rgba(0, 235, 120, 0.1);
    border-radius: 10px;
    box-shadow: 0px 8px 10px -2px rgba(61, 65, 63, 0.12);
    &.secondary {
      background: #e5ecf6;
    }
    &.secondary-varient {
      background: rgba(229, 236, 246, 0.5);
      box-shadow: none;
    }
    .widget-content {
      flex: 1;
      h3 {
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 0.5rem;
        color: #1c1c1c;
      }
      p {
        font-weight: 600;
        font-size: 1.5rem;
        color: #1c1c1c;
      }
    }
  }
  .dashboard-card {
    background: #fff;
  
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(18, 52, 77, 0.06);
    .dashboard-card-header {
      display: flex;
      align-items: center;
      padding: 1rem 1.5625rem 0.625rem;
      h3 {
        font-weight: 600;
        font-size: 16px;
        color: #1c1c1c;
      }
      .chart-label {
        margin-left: 3.125rem;
        border-left: 1px solid rgba(0, 0, 0, 0.2);
        span {
          display: inline-flex;
          align-items: center;
          margin: 0 0.625rem 0 1.25rem;
          font-size: 0.875rem;
          color: #1c1c1c;
          i {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 5px;
            border-radius: 30px;
          }
        }
      }
    }
    .dashboard-card-body {
      padding: 0 1.5625rem 1.25rem 1.5625rem;
    }
  }
  .real-time-container {
    height: 100%;
    padding-bottom: 20px;
    .dashboard-card {
      height: 100%;
    }
    .link {
      display: inline-block;
      color: #519e36;
      font-weight: 700;
      font-size: 0.875rem;
      cursor: pointer;
    }
    .real-time-content-list {
      li {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 1.25rem 0;
        h4 {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 1.5rem;
          color: #1c1c1c;
          margin-bottom: 5px;
          img {
            margin-left: 5.3125rem;
            width: 1.5rem;
          }
        }
        p {
          font-weight: 400;
          font-size: 1rem;
          color: #1c1c1c;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
    .content-data-container {
      .movie-detail {
        display: flex;
        align-items: center;
        img {
          width: 5.125rem;
          height: 2.875rem;
          border-radius: 6px;
          margin-right: 1.875rem;
        }
        p {
          font-weight: 400;
          font-size: 1rem;
          color: #1c1c1c;
        }
      }
      table {
        width: 100%;
        th {
          color: #1c1c1c;
          font-weight: 600;
          font-size: 1rem;
          padding: 8px 0;
          &:last-child {
            text-align: right;
          }
        }
        td {
          font-weight: 400;
          font-size: 0.875rem;
          color: #1c1c1c;
          padding: 8px 0;
        }
      }
    }
  }
  .location-container {
    .chart-container {
      margin-bottom: 1.25rem;
    }
    .country-progress-list {
      li {
        margin-bottom: 0.625rem;
        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            font-weight: 400;
            font-size: 0.75rem;
            color: #1c1c1c;
          }
        }
      }
      .ant-progress {
        line-height: 0;
        .ant-progress-inner{
          background-color: rgba(168, 197, 218, 0.3);
          .ant-progress-bg{
            height: 4px !important;
            background: #A8C5DA;
          }
        }
      }
    }
  }
  .ads-container{
    margin: 20px 0;
    
    
  }

}
