.mb-1,
.my-1 {
    margin-bottom: .25rem !important;
}

.mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.w-100 {
    width: 100% !important;
}

.align-items-center {
    align-items: center !important;
}

.flex-row {
    flex-direction: row !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.d-flex {
    display: flex !important;
}

.d-md-flex {
    display: flex !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.py-2 {
    padding-bottom: .5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: .5rem !important;
}

.ant-input-affix-wrapper {
    padding: .6rem 1rem;
    color: #455560;
    font-size: 0.875rem;
    line-height: 1.5;
    background-color: #ffffff;
    border: 1px solid #edf2f9;
    border-radius: 0.625rem ;
    max-height: none;


        &.ant-input {
            padding: 0;
            border: none;
            outline: none;
            border-radius: 0;
        }


    &:hover {
        border-color: #3e82f7;
    }

    &:focus, 
    &.ant-input-affix-wrapper-focused {
        border-color: #3e82f7;
        box-shadow: 0 0 0 2px rgba(#3e82f7, 0.2);
    }
}

// .ant-input-affix-wrapper-sm {
//     padding: .4rem .6rem;
//     max-height: none;
// }

// .ant-input-affix-wrapper-lg {
//     padding: .65rem 1rem;
//     max-height: none;

//     .ant-input-prefix,
//     .ant-input-suffix {
//         font-size: 1rem;
//     }
// }

.ant-btn-primary {
    background-color: #3e82f7;
    border-color: #3e82f7;
    color: #ffffff;

    &:hover,
    &:focus {
        color: #ffffff;
        background-color: lighten(#3e82f7, 5%);
        border-color: lighten(#3e82f7, 5%);
    }

    &:active {
        color: #ffffff;
        background-color: darken(#3e82f7, 5%);
        border-color: darken(#3e82f7, 5%);
    }

    &.active {
        color: #ffffff;
        background-color: darken(#3e82f7, 5%);
        border-color: darken(#3e82f7, 5%);

        &:hover,
        &:focus {
            background-color: darken(#3e82f7, 5%);
            border-color: darken(#3e82f7, 5%);
        }
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
        &:focus {
            box-shadow: none;
        }
    }

    &.ant-btn-background-ghost {
        background-color: transparent;
        border-color: #3e82f7;
        color: #3e82f7;

        &:hover,
        &:focus,
        &:active {
            border-color: lighten(#3e82f7, 5%);
            color: lighten(#3e82f7, 5%);
        }
    }

    &.disabled,
    &[disabled] {
        color: rgba(#455560, 0.25);
        background-color: #f7f7f8;
        border-color: #edf2f9;

        &.active,
        &:active,
        &:hover,
        &:focus {
            color: rgba(#455560, 0.25);
            background-color: #f7f7f8;
            border-color: #edf2f9;
        }
    }

    &.ant-btn-clicked {
        &:after {
            border-color: #3e82f7;
        }
    }
}

.ant-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}


.ant-avatar {
    font-size: 0.875rem;
    line-height: 1.5;
    color: #455560;
    text-align: center;
    background: #ededed;
    color: #FFFFFF;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 50%;
    margin-right: 5px;

    &.ant-avatar-icon {
        font-size: 1.125rem;
    }
}

.ant-avatar-lg {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;

    >* {
        line-height: 3rem;
    }

    &.ant-avatar-icon {
        font-size: 1.75rem;
    }
}

.ant-avatar-sm {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;

    >* {
        line-height: 1.5rem;
    }

    &.ant-avatar-icon {
        font-size: 0.875rem;
    }
}

.ant-avatar-square {
    border-radius: 0.625rem;
}

// @each $tone in $tone-loop {

//     .ant-avatar-#{nth($tone, 1)} {
//         color: #{nth($tone, 2)};
//         background: #{nth($tone, 3)};
//     }
// }    

// @each $state in $state-color-loop {
//     .ant-avatar-#{nth($state, 1)} {
//         color: #{nth($state, 2)};
//         background: rgba(nth($state, 2), 0.1);
//     }
// }

.avatar-string {

    .ant-avatar {
        margin-left: -5px;
        border: 2px solid #FFFFFF;
    }
}

.avatar-status-name {
    color: #1a3353;
    font-weight: 500;

    &.clickable {
        cursor: pointer;

        &:hover {
            color: #3e82f7;
        }
    }
}


.ant-dropdown {
    color: #455560;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 1050;
}

.ant-dropdown-menu {
    min-width: 10rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
    border-radius: 0.625rem;

    ul {
        list-style: none;
        padding-left: 0px;
    }
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    padding: 0.5rem 1.25;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #455560;
    line-height: 1.5;

    >a {
        display: block;
        padding: 0;
        margin: 0;
        // @include transition(all 0.3s); 
        color: #455560;
    }

    &:hover,
    &:focus {
        background-color: #fafafa;
        color: #1a3353;

        >a {
            color: #1a3353;
            background-color: transparent;
        }
    }
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
    color: rgba(#455560, .25);
    cursor: not-allowed;

    &:hover {
        color: rgba(#455560, .25);
        background-color: #ffffff;
        cursor: not-allowed;
    }
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {

    >a {
        background-color: #fafafa;
    }
}

.ellipsis-dropdown {
    cursor: pointer;
    font-size: 1.25rem;
    transform: rotate(90deg);
    display: inline-block;
    line-height: 1;
    padding: 0.3125rem;
    border-radius: 50%;
    transition: background-color 0.3s ease;

    &:hover,
    &:focus {
        background-color: rgba(#000000, 0.05);
    }
}

.text-capitalize {
    text-transform: capitalize !important;
}

.ant-tag-cyan {
    color: #04d182;
    background: rgba(4, 209, 130, 0.1);
    border-color: transparent;
    font-weight: 500;
}

.page-header-alt {
    background-color: #ffffff;
    padding: 25px;
    margin-top: -25px;
    margin-left: -25px;
    margin-right: -25px;

    // @include screen-mobile {
    //     margin-left: -$layout-content-gutter-sm;
    //     margin-right: -$layout-content-gutter-sm;
    // }

    &.overlap {
        margin-bottom: -5.3rem;
    }

    &.overlap-custom{
      margin-bottom: 2.3rem;
    }

    &.full-screen {
        position: relative;
        width: 100vw;
    }
}

.advanced-search {
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #edf2f9;
        border-radius: 6px;

        .ant-form-item {
            display: flex;
        }

        .ant-form-item-control-wrapper {
            flex: 1;
        }
    }

    .ant-form {
        max-width: none;
    }

    .search-result-list {
        margin-top: 16px;
        border: 1px dashed #e9e9e9;
        border-radius: 6px;
        background-color: #fafafa;
        min-height: 200px;
        text-align: center;
        padding-top: 80px;
    }
}

.bg-white {
    background-color: #ffffff !important;
}

.border-bottom {
    border-bottom: 1px solid #edf2f9 !important;
}

.ant-card-head {
    background: #ffffff;
    padding: 0 1.25rem;
    border-radius: 0.625rem 0.625rem 0 0;
    margin-bottom: -1px;
    min-height: 2.1875rem;
    border-bottom: 0;
    color: #1a3353;
    font-size: 16.8px;

    .ant-tabs-bar {
        border-bottom: 1px solid #edf2f9;
    }

    .ant-tabs {
        margin-bottom: -21px;
    }
}

.ant-card-head-title {
    font-weight: bold;
    padding-bottom: 0;
}

.ant-form-item-label {
    color: #455560;

    label {
        color: #455560;
        font-weight: 500;
        height: 2.51rem;
    }

    >label {
        &.ant-form-item-required {
            &:before {
                display: inline-block;
                color: rgba(#72849a, 0.7);
                font-size: 0.875rem;
                font-family: "Roboto", -apple-system, BlinkMacSystemFont,
                    "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
                    "Helvetica Neue", Helvetica, Arial, sans-serif;
                line-height: 1.5;
                content: '*';
            }
        }
    }
}


.ant-form-item-control-input {
    min-height: 2.51;
}

.ant-form-small {
    .ant-form-item-label {
        >label {
            height: 3.175rem;
        }
    }

    .ant-form-item-control-input {
        min-height: 3.175rem;
    }
}

.ant-form-large {
    .ant-form-item-label {
        >label {
            height: 3.857rem;
        }
    }

    .ant-form-item-control-input {
        min-height: 3.857rem;
    }
}

.ant-form-item-has-error {

    .ant-input,
    .ant-input-affix-wrapper {
        background-color: #ffffff;
        border-color: #ff6b72;

        &:hover {
            background-color: #ffffff;
            border-color: #ff6b72;
        }

        &:focus {
            border-color: #ff6b72;
            box-shadow: 0 0 0 2px rgba(#ff6b72, 0.2);
        }

        &:not([disabled]) {

            &:hover {
                border-color: #ff6b72;
            }
        }
    }

    .ant-input-focused,
    .ant-input-affix-wrapper-focused {
        border-color: #ff6b72;
        box-shadow: 0 0 0 2px rgba(#ff6b72, 0.2);
    }

    .ant-input-number,
    .ant-picker {
        border-color: #ff6b72;
    }

    .ant-input-number,
    .ant-picker {
        &:focus {
            border-color: #ff6b72;
            box-shadow: 0 0 0 2px rgba(#ff6b72, 0.2);
        }
    }

    .ant-input-number-focused,
    .ant-picker-focused {
        border-color: #ff6b72;
        box-shadow: 0 0 0 2px rgba(#ff6b72, 0.2);
    }

    .ant-select {
        .ant-select-selector {
            border-color: #ff6b72 !important;
        }
    }

    .ant-form-item-explain,
    .ant-form-item-split {
        color: #ff6b72;
    }

    &.ant-form-item-has-feedback {
        .ant-form-item-children-icon {
            color: #ff6b72;
        }
    }
}

.ant-form-item-has-warning {

    .ant-input,
    .ant-input-affix-wrapper {
        background-color: #ffffff;
        border-color: #ffc542;

        &:hover {
            background-color: #ffffff;
            border-color: #ffc542;
        }

        &:focus {
            border-color: #ffc542;
            box-shadow: 0 0 0 2px rgba(#ffc542, 0.2);
        }

        &:not([disabled]) {
            &:hover {
                border-color: #ffc542;
            }
        }
    }

    .ant-input-focused,
    .ant-input-affix-wrapper-focused {
        border-color: #ffc542;
        box-shadow: 0 0 0 2px rgba(#ffc542, 0.2);
    }

    .ant-input-prefix {
        color: #ffc542;
    }

    &.ant-form-item-has-feedback {
        .ant-form-item-children-icon {
            color: #ffc542;
        }
    }
}

.ant-form-item-has-success {

    &.ant-form-item-has-feedback {
        .ant-form-item-children-icon {
            color: #04d182;
        }
    }
}

.ant-form-item-is-validating {
    &.ant-form-item-has-feedback {
        .ant-form-item-children-icon {
            color: #3e82f7;
        }
    }
}


.ant-form-explain,
.ant-form-extra {
    color: #72849a;
    line-height: 1.5;
    margin-top: 5px;
}

.ant-form-item {
    font-size: 0.875rem;
    line-height: 1.5;
    color: #455560;
    margin: 0px;
    margin-bottom: 24px;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
    line-height: 1.5;
    padding-bottom: 0.35rem;

    >label {
        height: auto;
        width: 100%;
    }
}

.ant-input {
    padding: 0.6rem 1rem;
    line-height: 1.5;
    color: #455560;
    height: auto;
    border: 1px solid #edf2f9;
    border-radius: 0.625rem;
    background-color: #ffffff;
}

.ant-input-number-custom {
    padding: 0.6rem 1rem;
    line-height: 1.5;
    color: #455560;
    height: 3rem;
    border: 1px solid #edf2f9;
    border-radius: 0.4rem;
    background-color: #ffffff;
}

.ant-upload.ant-upload-drag {
    border: 2px dashed #edf2f9;
    border-radius: 0.625rem;
    padding: 0 1rem;
    background: #ffffff;
    min-height: 10.75rem;
    display: flex;
    align-items: center;
}

.display-3 {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.5;
}

.ant-btn-custom-upload {
    font-size: 0.875rem;
    padding: 0.5rem 1.5rem;
    line-height: 1.5;
    border-color: #edf2f9;
    color: #455560;
    height: 2rem;
    border-radius: 0.625rem;
}

.ant-btn-custom {
    font-size: 0.875rem;
    padding: 0.5rem 1.25rem;
    line-height: 1.5;
    border-color: #edf2f9;
    color: #455560;
    height: 2.8rem;
    border-radius: 0.625rem;
}

// .ant-input-number-input {
//     width: 100%;
//     height: 30px;
//     padding: 0 11px;
//     text-align: left;
//     background-color: transparent;
//     border: 0;
//     border-radius: 2px;
//     outline: 0;
//     -webkit-transition: all 0.3s linear;
//     transition: all 0.3s linear;
// }

.add-custom {
    color: #ffffff;
}

.input-affix-wrapper-upload {
    border-radius: 6px;
    border: 1px solid #edf2f9;
}

.custom-icon {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 800;
}

.font-weight-semibold {
    font-weight: 500 !important;
  }

  .ant-modal-body {
    padding: 1.5rem;
    font-size: 14px;
    line-height: 1.5;
}
.ant-picker-custom 
{
    color: #455560;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 4px 11px 4px;
    background: #ffffff;
    border: 1px solid #edf2f9;
    border-radius: 0.625rem;
    height: 2.5125rem;
}

section.ant-layout.layout-content .ant-layout-content.content-ant {
    padding: 0px !important;
  }
  
  /* .ant-card.criclebox {
    box-shadow: 0px 20px 27px #0000000d;
    border-radius: 12px;
  }

  .ant-card.criclebox .project-ant {
    padding-left: 14px;
    padding-right: 14px;
  } */

  .ant-card.headerNull table th {
    /* padding-left: 24px;
    padding-right: 24px;
    padding-top: 14px;
    padding-bottom: 14px; */
    display:none;
  }

  .number span {
    font-weight: 600;
    color: #8c8c8c;
    font-size: 14px;
  }
  .number h3 {
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 30px;
  }
  
  .number h3 small {
    font-weight: 600;
    font-size: 14px;
  }

  .bnb2 {
    color: #52c41a;
    font-weight: 700;
  }

  .h-full {
    height: 100%;
  }

  .icon-box {
    width: 48px;
    height: 48px;
    text-align: center;
    background: #1890ff;
    color: #fff;
    border-radius: 0.5rem;
    margin-left: auto;
    line-height: 55px;
  }
  
  .icon-box span {
    color: #fff;
    font-size: 24px;
  }

  .cardbody .ant-card-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  .ant-card.criclebox .project-ant {
    padding-left: 24px;
    padding-right: 24px;
  }

  .project-ant h4 {
    margin: 0px;
    font-size: 16px;
    font-weight: bold;
    color: #141414;
  }
  
  span.blue {
    margin-left: 5px;
    color: #1890ff;
    font-weight: 600;
  }
  .project-ant h5 {
    margin: 0px;
    color: #141414;
    font-weight: 700;
  }

  .project-ant {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .ant-typography.lastweek {
    color: #8c8c8c;
    font-weight: 600;
  }
  
  .mb-24 {
    margin-bottom: 14px;
  }

  .ant-filtertabs {
    text-align: right;
  }

  .antd-pro-pages-dashboard-analysis-style-salesExtra a {
    display: inline-block;
    padding: 7px 10px;
    color: #595959;
    border: 1px solid #d9d9d9;
    text-align: center;
    border-right: 1px solid transparent;
  }
  
  .antd-pro-pages-dashboard-analysis-style-salesExtra a:last-child {
    border-right: 1px solid #d9d9d9;
    border-radius: 0px 5px 5px 0px;
  }
  
  .antd-pro-pages-dashboard-analysis-style-salesExtra a:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  
  .antd-pro-pages-dashboard-analysis-style-salesExtra a:hover {
    color: #1890ff;
    border: 1px solid #1890ff;
  }
  .antd-pro-pages-dashboard-analysis-style-salesExtra a.active {
    color: #1890ff;
    border: 1px solid #1890ff;
  }

  .ant-list-box th {
    text-align: left;
    border-bottom: 1px solid #0000000d;
    padding: 8px 0px;
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 600;
  }
  
  .ant-list-box td {
    text-align: left;
    border-bottom: 1px solid #0000000d;
    padding: 16px 25px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
  }

  .ant-list-box h5 {
    font-size: 14px;
  }

  .ant-list-box table tr:hover td {
    background: #fafafa;
  }

  .width-100 {
    width: 100%;
  }

  .avatar-sm {
    width: 25px;
  }

  .m-10,
.mr-10,
.mx-10 {
  margin-right: 10px !important;
}

.text-xs {
    color: #8c8c8c;
  }

  .percent-progress .ant-progress-bg {
    height: 3px !important;
  }

  .percent-progress span.ant-progress-text {
    display: flex;
    margin-top: -27px;
    color: #8c8c8c;
    position: relative;
    margin-left: 0px;
  }
  /* .percent-progress .ant-progress-outer {
    margin-right: 0px;
    padding-right: 0px;
  } */
  
  .percent-progress .ant-progress-status-exception .ant-progress-text {
    color: #ff4d4f;
  }

  .uploadfile {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 15px;
  }

  .uploadfile.pb-15 {
    padding-bottom: 16px;
  }

  .uploadfile.shadow-none
  .ant-upload.ant-upload-select.ant-upload-select-text
  .ant-btn.ant-full-box {
  box-shadow: none;
}

  
  .uploadfile .ant-upload.ant-upload-select {
    display: block;
  }

  .ant-full-box {
    width: 100%;
    border: 1px dashed #d9d9d9;
    padding: 10px;
    height: auto;
    color: #141414;
    border-radius: 10px;
  }

  .timeline-box h5 {
    margin-bottom: 0px;
    font-weight: 700;
  }

  .ant-typography.lastweek {
    color: #8c8c8c;
    font-weight: 600;
  }

  .timelinelist h5 {
    font-size: 14px;
    font-weight: 600;
  }
  
  .timelinelist span {
    color: #8c8c8c;
    font-size: 12px;
  }
  .col-content {
    flex-grow: 1;
    padding: 24px;
    margin-right: 0;
    border-radius: 8px;
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .p-20 {
    padding: "0px 20px 0px 0px";
  }

  .ant-muse span {
    color: #8c8c8c;
    font-weight: 600;
  }
  
  .ant-muse h5 {
    margin: 0px 0px 15px !important;
    font-size: 20px;
  }

  .card-credit .card-footer {
    display: flex;
    align-items: flex-end;
  }

  .card-credit .card-footer h6,
.card-credit .card-footer p {
  margin-bottom: 0;
}
.card-credit .col-logo img {
  max-width: 50px;
}
.card-credit .card-footer h6 {
  font-weight: 700;
}

.card-project.ant-card .card-footer {
    margin-top: 22px;
  }
  
  .ant-avatar-group {
    align-items: center;
  }

  .avatar-group .tootip-img + .tootip-img {
    margin-left: -10px;
  }

  /* .ant-avatar-group .ant-avatar-square {
    border-radius: 4px;
  } */

  .tootip-img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 1px solid #fff;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  .layout-dashboard-rtl .avatar-group.mt-2 {
    direction: ltr;
  }

  .icon-move-right .anticon-right {
    color: #40a9ff;
    margin-left: 2px;
  }
  .icon-move-right {
    color: #1890ff;
  
    display: block;
  }

  .gradent .icon-move-right {
    color: #fff;
  }
  
  .gradent .icon-move-right .anticon-right {
    color: #fff;
  }

  .icon-move-right {
    margin-bottom: 15px;
  }

  .ant-cret img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .bar-chart line.apexcharts-xaxis-tick {
    stroke: transparent;
  }

  .bar-chart {
    background: transparent
      linear-gradient(62deg, #00369e 0%, #005cfd 53%, #a18dff 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 0px 4px 6px #0000001f;
  
    border-radius: 8px;
  }

  .bar-chart line.apexcharts-xaxis-tick {
    stroke: transparent;
  }

  /* .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  } */

 

  .avatar-info h5 {
    font-weight: bold;
    font-size: 10px;
    line-height: 8px;
    margin-bottom: 3px;
    margin-left: 4px;
  }
  
  .avatar-info p {
    font-size: 8px;
    font-weight: 400;
    color: #8c8c8c;
    margin: 0px;
    margin-left: 4px;
  }

  .tag-primary {
    border-radius: 6px;
    padding: 2px 12px;
    font-size: 12px;
    height: auto;
    line-height: 8px;
    font-weight: 200;
  }


  /* .ant-card.criclebox table tr {
   padding: 20px;
  } */

  .sales ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  
  .sales ul li:first-child span.anticon {
    color: #b37feb;
  }
  
  .sales ul li:last-child span.anticon {
    color: #1890ff;
  }
  .sales ul li {
    color: #8c8c8c;
    margin-bottom: 3px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
  }

  .linechart h5 {
    font-weight: 700;
    margin: 0px;
  }
  .linechart {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  

.widget-2 .icon {
    width: 64px;
    height: 64px;
    margin: auto;
    background-color: #1890ff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 16px; */
  }
  .widget-2 .ant-statistic-title h6 {
    font-weight: 600;
  }
  .widget-2 .ant-statistic-title p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .widget-2 .ant-statistic-content {
    font-family: open sans, Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 20px;
    padding: 12px 16px;
    border-top: 1px solid #f5f5f5;
    text-align: center;
  }
  .widget-2 .ant-statistic-title {
    text-align: center;
    padding: 16px;
  }
  
  .widget-2 .ant-card-body {
    padding: 0;
  }
  .widget-2 .icon img {
    width: 30px;
  }

  /* .ant-statistic-content {
    float: left;
  } */

  .ant-border-space th:before {
    display: none;
  }

  .recent-info h5 {
    font-weight: bold;
    font-size: 14px;
    line-height: 8px;
    margin-bottom: 3px;
    margin-left: 4px;
  }
  
  .recent-info p {
    font-size: 12px;
    font-weight: 400;
    color: #8c8c8c;
    margin: 0px;
    margin-left: 4px;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .image-container {
    /* margin-bottom: 10px; */
    padding: 16px;
  }
  
  .text-below {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  
  /* .divider {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
  }
   */
   .values-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: 20px;
  }
  
  .left-value,
  .right-value {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .left-value p,
  .right-value p {
    margin-bottom: 5px;
  }
  
  .left-value h3,
  .right-value h3 {
    font-size: 12px;
    font-weight: bold;
    margin-top: 0;
  }
  
  .divider {
    width: 1px;
    height: 100%;
    background-color: #ccc;
    margin: 0 10px;
  }
  .vertical-divider {
    height: 40px; /* Set the desired height */
    margin: 0 10px;
    border-right: 2px solid #595959;
  }
  
  .text-para{
    color:rgba(0, 0, 0, 0.45);
  }

  .ant-card {
    line-height: 1.5;
    color: #455560;
    background: #ffffff;
    border-radius: 0.625rem;
    font-size:  0.875rem;
    margin-bottom: 1rem;
  }   
  
  .ant-card-bordered {
    border: 1px solid #edf2f9;
  }
  
 
  
  .ant-card-extra {
    padding-top:  1.25rem;
    padding-bottom: 0;
  }
  
  /* 
   */
  .ant-card-body {
    padding:   1.25rem 1.25rem;
  }
  
  .ant-card-meta-description {
    color:#72849a ;
  }
  
  .ant-card-type-inner {
  
    .ant-card-head {
        padding: 0  1.25rem;
        background:#f7f7f8;
    }
  
    .ant-card-body {
        padding:   1.25rem 1.25rem;
    }
  }
  
  .ant-card-grid {
    border-radius: 0;
    border: 0;
    box-shadow: 1px 0 0 0 #edf2f9,
                0 1px 0 0 #edf2f9,
                1px 1px 0 0 #edf2f9,
                1px 0 0 0 #edf2f9 inset,
                0 1px 0 0 #edf2f9 inset;
    width: auto;
    padding:   1.25rem  1.25rem;
  }    
  
  .ant-card-actions {
    border-top: 1px solid #edf2f9;
    background: #fafafa;
  
    > li {
        float: left;
        text-align: center;
        margin: 15px 0;
        color: #455560;
  
        & :not(:last-child) {
            border-right: 1px solid #edf2f9;
        }
        
        > span {
  
            .anticon {
                font-size: 16px;
                line-height: 1.5;;
            }  
            
            &:hover {
                color: #3e82f7 ;
            }
        }
    }
  }
  
  .ant-card-footer {
    margin-left:  1.25rem;
    margin-right:  1.25rem;
    margin-bottom:  1.25rem ;
    padding: 1rem  1.25rem;
  }
  
  .ant-card-meta-title {
    color:#1a3353;
    font-weight: bold;
    font-size: 1.05rem;
  }
  
  .ant-card-hoverable {
  
    &:hover {
        box-shadow:  0 2px 8px rgba(#000000,.09);
        border-color: #edf2f9;
    }
  }
  
  .off-gutter-x {
    margin-left:  1.25rem;
    margin-right:  1.25rem;
  }
  
  .off-gutter-top {
    margin-top:  1.25rem;
  }
  
  .off-gutter-bottom {
    margin-bottom:  1.25rem ;
  }
  
 .custom-title-card .ant-card-head-title
{
    border-bottom: 1px solid #edf2f9;
    padding-bottom: 20px;
}

.verify-account-background {
  background: #fff8dd;
  border: 1px dashed rgb(255, 199, 0);
  padding: 25px;
  display: flex;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 30px;

  h3{
    font-size: 1.2rem;
  }
  p{
font-size: 1rem;
  }
}

.left-content {
  flex: 0 0 auto; 
}

.right-content {
  flex: 1;
  margin-left: 10px;

}

.verify-account-icon {
  width: 50px; 
  height: 50px; 
  margin-right: 10px; 
}

.verify-account-checkbox{
  margin-top: 20px;
  color: #99A1B7;
  font-size: 1.5rem;
}

.bg-gray-lightest {
  background-color: #f7f7f8 !important;
}

.p-3 {
  padding: 1rem !important;
}

.rounded {
  border-radius: 0.625rem !important;
}

.edit {
  color: blue;          
  text-decoration: underline;   
  cursor: pointer;    
}


.edit img {
  text-decoration: none; 
  border: none;       
}
.ant-select-multiple .ant-select-selection-item-remove {
  display: none;
}

.card-container {
  
  justify-content: center; 
  margin: 0 auto;
  // max-width: 990px; 
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  justify-content: center; 
  margin: 0 auto; 
  max-width: 600px; 
}

.ant-modal-content {
  background-color: #ffffff;
  border-radius: 0.625rem;
  box-shadow:  0 4px 12px rgba(#000000, 0.15);
}

.ant-modal-confirm-btns
{
  float:right;
  margin-top:24px;
  
  }
.ant-modal-confirm .ant-modal-confirm-btns
 button+button
 {
  margin-bottom:0;
  margin-left:8px;
  border-radius: 5px
}
.ant-btn
 {
  border-radius: 5px
}

.ant-radio-button-wrapper:last-child{
  border-radius: 0 5px 5px 0;
}
.ant-radio-button-wrapper:first-child{
  border-radius: 5px 0 0 5px;
}

.apexcharts-toolbar{
  display: none !important;
}

.mb-0,
.my-0
{
  margin-bottom:0 !important
}

.view-panel{
  font-size:15px;
  color:	#696969!important;
}
// .custom-title-card  .ant-card-head-title::after {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 1px;
//     background-color: #000; /* Change the line color as desired */
//   }
// .container
// {
//     position:relative;
//     margin-left:auto;
//     margin-right:auto;
//     padding-right:9px;
//     padding-left:9px
// }
// @media (min-width: 576px)
// {
//     .container
//     {
//         padding-right:9px;
//         padding-left:9px
//     }
// }
// @media (min-width: 768px)
// {
//     .container
//     {padding-right:9px;
//         padding-left:9px
//     }
// }
// @media (min-width: 992px)
// {
//     .container
//     {
//         padding-right:9px;
//         padding-left:9px
//     }
// }
// @media (min-width: 1200px)
// {
//     .container
//     {
//         padding-right:9px;
//         padding-left:9px
//     }
// }
// @media (min-width: 576px)
// {
//     .container
//     {
//         width:540px;
//         max-width:100%
//     }
// }
// @media (min-width: 768px)
// {
//     .container
//     {
//         width:720px;
//         max-width:100%
//     }
// }
// @media (min-width: 992px)
// {
//     .container
//     {
//         width:960px;
//         max-width:100%
//     }
// }
// @media (min-width: 1200px)
// {
//     .container
//     {
//         width:1140px;
//         max-width:100%
//     }
// }