@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
@import "./variables.scss";
html {
  background: #f2f4fc;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e7eaf1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/******** Header Design ******/
.header {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeff2;
  height: 3.75rem;
  .logo-section {
    width: 16rem;
    display: flex;
    justify-content: space-between;
    padding: 0rem 1.625rem;
    align-items: center;
    .logo {
      width: 7.875rem;
      img {
        width: 100%;
      }
    }
  }
  .right-section {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    padding: 0rem 1.875rem;
    .header-action {
      display: flex;
      align-items: center;
    }
    .add-video {
      margin-left: 1.625rem;
    }
  }
}
.main {
  padding-top: 3.75rem;
}
.main ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
  position: fixed;
  width: 256px;
  height: 100%;
}
*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: 'Roboto', 'Segoe UI',
  'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue',
  Helvetica, Arial, sans-serif;
}
html {
  font-size: 14px;
}
body {
  line-height: initial;
  font-size: initial;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: middle;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  display: inline-block;
}
button,
a {
  cursor: pointer;
  text-decoration: none;
}
:focus {
  outline-color: $primary;
}
.primary-btn {
  background: $primary;
  padding: 0.625rem 1.875rem;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 0.875rem;
  border-color: transparent;
  transition: 0.3s;
  &:hover,
  &:focus {
    background: #34aff9;
    color: #fff;
  }
  &:active {
    background: #098edf;
    color: #fff;
  }
  &:disabled {
    background: #c2cfe0;
    color: #fff;
    border-color: #c2cfe0;
  }
}
.success-btn {
  background: $success-varient-color;
  border-color: $success-varient-color;
  color: #fff;
  &:hover,
  &:focus {
    background: $success-varient-color;
    color: #fff;
    border-color: $success-varient-color;
  }
  &:active {
    background: $success-varient-color;
    color: #fff;
    border-color: $success-varient-color;
  }
  &:disabled {
    background: #c2cfe0;
    color: #fff;
    border-color: #c2cfe0;
  }
}
.dropdown {
  position: relative;
  .flag-img {
    margin-right: 8px;
    width: 22px;
  }
  .dropbtn {
    border: 0;
    padding: 4px 0.75rem;
    background: transparent;
    font-size: 0.9375rem;
    color: #586373;
    font-weight: 500;
    outline: none;
  }
  .dropdown-content {
    position: absolute;
    left: 0;
    top: 100%;
    padding: 6px;
    margin-top: 12px;
    min-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 3px 15px rgba(123, 169, 200, 0.16);
    animation: slideUp 0.3s;
    &.drop-right {
      left: auto;
      right: 0;
    }
    ul {
      li {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0.625rem 0.9375rem;
        border-bottom: 1px solid #e1e8ed;
        color: #586373;
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;
        transition: 0.3s;
        &::before {
          content: "";
          position: absolute;
          width: 3px;
          height: 70%;
          background: #109cf1;
          top: 50%;
          transform: translateY(-50%);
          left: -6px;
          opacity: 0;
          transition: 0.3s;
        }
        &:last-child {
          border: 0;
        }
        &:hover {
          color: $primary;
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}
.notification-dropdown {
  margin-left: 1.25rem;
  .ant-dropdown-trigger {
    position: relative;
    img {
      width: 1.5rem;
    }
    .indicator {
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #ff4141;
      right: 0px;
      top: 0px;
    }
  }
}
.notification-dropdown-content {
  width: 20rem;
  .ant-dropdown-menu-item {
    padding: 5px 0px;
  }
  .notification-item {
    width: 100%;
    margin: 0;
    padding: 10px 20px;

    h3 {
      font-weight: 600;
      font-size: 0.875rem;
      color: #000;
      margin-bottom: 0.3125rem;
    }
    p {
      color: #a0b7c9;
      font-weight: 400;
      font-size: 0.8125rem;
      white-space: normal;
    }
  }
}
.user-dropdown {
  margin-left: 1.5625rem;
  .ant-dropdown-trigger {
    img {
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 30px;
    }
  }
}
.page-header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  padding: 1.0625rem 2.1875rem;
  box-shadow: 0px 2px 4px rgba(18, 52, 77, 0.06);
  background: #fff;
  z-index: 99;
  .left-col {
    display: flex;
    align-items: center;
    h1 {
      margin: 0;
      img {
        width: 9.375rem;
      }
    }
    .hamburger-btn {
      margin-left: auto;
      padding: 0;
      border: 0;
      background: transparent;
      outline: none;
      img {
        width: 0.9375rem;
      }
    }
  }
  .right-col {
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 2.5rem;
    h2 {
      font-weight: 600;
      font-size: 1.0625rem;
      margin: 0;
    }
    .header-items {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }
}
.language-dropdown {
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .ant-select {
    border: 0;
    font-size: 1rem;
    color: #1c1c1c;
    padding-left: 0;
    .ant-select-selector {
      padding-left: 1.75rem !important;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      padding-right: 0 !important;
    }
    .ant-select-arrow {
      display: none;
    }
  }
  .flag-img {
    width: 1.5rem;
    margin-right: 5px;
  }
}
.ant-select-item-option {
  padding: 8px 15px;
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;

    .flag-img {
      width: 1.5rem;

      margin-right: 8px;
    }
  }
}
.side-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  top: 0;
  width: 15.625rem;
  background: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  z-index: 999;
  .logo {
    padding: 1.3125rem 1rem 1.25rem 1.5625rem;
    img {
      height: 2rem;
    }
  }
  .side-nav {
    flex: 1;
    padding: 0;
    overflow: auto;
    .ant-menu-inline-collapsed {
      width: auto;
    }
    ul {
      border-right: 0;
      .ant-menu-submenu {
        padding-bottom: 0;
        .ant-menu-sub {
          background: rgba(0, 0, 0, 0.05);
        }
        &.ant-menu-submenu-open,
        &.ant-menu-submenu-selected {
          // .ant-menu-submenu-title img {
          //   filter: brightness(0) saturate(100%) invert(53%) sepia(100%)
          //     saturate(411%) hue-rotate(90deg) brightness(95%) contrast(80%);
          // }
          .ant-menu-submenu-arrow {
            color: #3e82f7 !important;
          }
        }
        &.ant-menu-submenu-active {
          .ant-menu-submenu-arrow {
            color: #3e82f7 !important;
          }
        }
        .ant-menu-submenu-title {
          width: 100%;
          padding: 0.9375rem 1.875rem !important;
          height: auto;
          line-height: normal;
          background: transparent !important;
          img {
            width: 1.5rem;
            margin-right: 1.25rem;
            transition: 0.2s;
            flex-shrink: 0;
          }
          span {
            font-size: 1rem;
            color: #1c1c1c;
          }
          .ant-menu-submenu-arrow {
            color: rgba(0, 0, 0, 0.4);
          }
        }
        // &.ant-menu-submenu-selected {
        //   // img {
        //   //   filter: invert(33%) sepia(99%) saturate(2169%) hue-rotate(217deg)
        //   //   brightness(101%) contrast(98%);
        //   // }
        // }
        .ant-menu-item {
          padding-left: 3.75rem !important;
          margin: 0;

          &.ant-menu-item-selected {
            a {
              color: #3e82f7 !important;
            }
          }
          a {
            background: transparent !important;
          }
          &.ant-menu-item-active,
          &.ant-menu-item-selected {
            background: transparent;
            box-shadow: none;
          }
        }
      }
      .ant-menu-item {
        height: auto;
        line-height: normal;
        padding: 0 1rem !important;
        width: 100%;
        &::after {
          content: none;
        }

        &.ant-menu-item-active,
        &.ant-menu-item-selected {
          background: transparent;
          a {
            background: rgba(0, 0, 0, 0.05);
          }

          // img {
          //   filter: brightness(0) saturate(100%) invert(53%) sepia(100%)
          //     saturate(411%) hue-rotate(90deg) brightness(95%) contrast(80%);
          // }
        }
        a {
          display: flex;
          align-items: center;
          font-size: 1rem;
          white-space: nowrap;
          overflow: hidden;
          transition: 0.2s;
          color: #1c1c1c;
          padding: 0.9375rem;
          border-radius: 8px;
          &:hover {
            color: #3e82f7;
          }
          img {
            width: 1.5rem;
            margin-right: 20px;
            transition: 0.2s;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}
.page-container {
  padding-left: 15.625rem;
  min-height: 100vh;
  background: #ebeff3;
  transition: 0.3s;
  &.sidemenu-closed {
    padding-left: 5.625rem;
    .side-menu {
      width: 5.625rem;
      ul {
        .ant-menu-item {
          padding: 0 30px;
        }
      }
      .ant-menu-submenu .ant-menu-submenu-title span {
        opacity: 0;
        transition: 0.3s;
      }
    }
  }
  .panel-container {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    h2 {
      font-size: 1.375rem;
      font-weight: 700;
      color: #0d1734;
      margin-bottom: 1.25rem;
    }
    .inner-scroll-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      .table-container {
        flex: 1;
        overflow: auto;
        .ant-table-wrapper {
          height: 100%;
          .ant-spin-nested-loading {
            height: 100%;
            .ant-spin-container {
              height: 100%;
              display: flex;
              flex-direction: column;
              .ant-table {
                flex: 1;
                overflow: auto;
                .ant-table-container {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  &::before,
                  &::after {
                    content: none;
                  }

                  .ant-table-cell-scrollbar {
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.app-page-container {
  padding: 1.25rem 1.75rem;
}
.page-header-block {
  display: flex;
  align-items: center;
  margin-bottom: 0.9375rem;
  h2 {
    margin: 0 1.5625rem 0 0 !important;
  }
  .header-action {
    display: flex;
    align-items: center;
    margin-left: auto;

    .btn-add {
      margin-left: 10px;
      height: 36px;
      padding: 0px 1.125rem;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 4px;
      //background: #416dff;
      border: 0;
      img {
        width: 1.25rem;
        margin-right: 8px;
      }
    }
  }
}
.filter-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.9375rem;
  .filter-btn {
    background: #e2e6ed;
    border: 0;
    padding: 0;
    width: 34px;
    height: 34px;
    border-radius: 4px;
    margin-left: 0.9375rem;
    &:hover {
      background: #ced4df;
    }
  }
  h2 {
    margin: 0 1.5625rem 0 0 !important;
  }
  .search-field {
    width: 20%;
    padding: 0.5rem 0.9375rem;
    border: 1px solid #d6d9e8;
    .input-field-wrap .ant-input {
      font-size: 0.9375rem;
      padding: 0px 0.625rem;
    }
  }
  .video-count {
    display: inline-flex;
    align-items: center;
    background: #e2e6ed;
    padding: 3px 15px;
    border-radius: 30px;
    font-size: 12px;
    color: #0d1734;
    strong {
      margin-right: 5px;
      font-weight: 600;
    }
  }
  .right-col {
    display: flex;
    align-items: center;
    margin-left: auto;
    .filter-btn {
      background: #e2e6ed;
      border: 0;
      padding: 0;
      width: 34px;
      height: 34px;
      border-radius: 4px;
      &:hover {
        background: #ced4df;
      }
    }
    .btn-add {
      margin-left: 10px;
      height: 36px;
      padding: 0px 1.125rem;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 4px;
      background: #416dff;
      border: 0;
      &:hover,
      &:focus {
        background: #34aff9;
        color: #fff;
      }
      img {
        width: 1.25rem;
        margin-right: 8px;
      }
    }
  }
  .filter-right-col {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-bottom: 0;

    .select-dropdown {
      margin-left: 0.375rem;
    }
    .add-btn {
      height: 40px;
      padding: 0px 1.125rem;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 4px;
      background: #416dff;
      border: 0;
    }
    .add-btn,
    .upload-btn {
      margin-left: 1.5625rem;
    }
  }
}
.filter-items-container {
  display: flex;
  align-items: center;
  h4 {
    font-weight: 600;
    font-size: 0.875rem;
    color: #3f4a6c;
    margin-right: 1.25rem;
  }
  .select-dropdown {
    margin-left: 0.625rem;
    width: 180px;
    .ant-select {
      width: 100%;
      border: 0;
    }
  }
  .add-btn,
  .upload-btn {
    margin-left: 1.5625rem;
  }
}




.nav-profile {
  width: 14.5rem;
  background: #fff;
  box-shadow: 0px 2px 12px 7px rgba(18, 52, 77, 0.06);
  border-radius: 4px;
  overflow: hidden;

  .nav-profile-header {
    padding: 0.7rem 1rem;
    border-bottom: 1px solid $border-color;
  }

  .nav-profile-body {
    padding: 0.625rem 0;

    .ant-menu-vertical > .ant-menu-item {
      height: 2.1875rem;
      line-height: 2.1875rem;
    }
  }
}

.ant-select {
  color: #3f4a6c;
  font-size: 0.875rem;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #edf2f9;
  border-radius: 5px;
  padding: 0 0.9375rem;

  .ant-select-selector {
    border: 0 !important;
    padding: 4px 0 !important;
    height: auto !important;
    box-shadow: none !important;
    .ant-select-selection-item {
      padding-right: 1.875rem;
    }
  }
  .ant-select-arrow {
    background: url(../images/icons/down-arrow.svg) no-repeat center;
    background-size: cover;
    width: 9px;
    height: 5px;
    margin-top: -3px;
    svg {
      opacity: 0;
    }
  }
}
.form-group {
  display: inline-flex;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  padding: 0 0.9375rem;

  &.dropdown {
    .dropbtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.625rem 0;
      font-size: 0.875rem;
      color: #636363;
      min-width: 6.25rem;
    }
  }
  .search-icon {
    width: 14px;
    margin-right: 5px;
  }
  .input-field-wrap {
    display: flex;
    align-items: center;
    flex: 1;
    .form-field
    // .ant-input 
    {
      font-size: 0.9375rem;
      padding: 0.625rem 0.9375rem;
      color: #000000;
      border: 0;
      background: transparent;
      outline: none;
      letter-spacing: 0.25px;
      box-shadow: none !important;
      width: 100%;
      &::placeholder {
        color: rgba(8, 49, 114, 0.3);
      }
      & + .undefined-action {
        display: none;
      }
    }
  }
}
.table-container {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 3px 1px rgba(214, 217, 232, 0.3);
  .ant-table-column-sorters {
    padding: 0.9375rem 1.5625rem !important;
  }
  .ant-table {
    background: transparent;
  }

  table {
    border-collapse: collapse;
    thead {
      tr {
        th {
          position: sticky;
          top: 0;
          z-index: 3;
          padding: 0.9375rem 1.5625rem;
          text-align: left;
          font-size: 0.875rem;
          font-weight: 500;
          white-space: nowrap;
          border: 0;
          background: #fff;
          border-bottom: 1px solid #e9eaf4;
          color: #3f4a6c;
          &.movie-td {
            border-right: 1px solid #e9eaf4;
          }
          &.ant-table-cell-fix-left {
            z-index: 4;
          }
          .filter-btn {
            border-radius: 3px;
            border: 1px solid #e2e2e2;
            padding: 10px;
            background: #fff;
            display: flex;
            margin-left: auto;
          }
        }
      }
    }
    tbody {
      .ant-table-row,
      tr {
        border-bottom: 1px solid #e1e8ed;
        background: #fff;
        &:hover {
          & > td {
            background: #f5f7ff;
          }
        }
        &.ant-table-measure-row {
          border-bottom: 0;
        }
        &:last-child {
          border-bottom: 0;
        }
        td {
          padding: 0.9375rem 1.5625rem;
          font-size: 0.875rem;
          color: #0d1734;
          border: 0;
          a:hover {
            text-decoration: underline;
          }
          &.movie-td {
            border-right: 1px solid #e1e8ed;
          }
          .tbl-action {
            display: inline-flex;
            .btn {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              min-width: 1.875rem;
              min-height: 1.875rem;
              background: transparent;
              border: 0;
              padding: 3px;
              border-radius: 6px;
              box-shadow: none;
              transition: 0.3s;
              img {
                margin: 0;
              }
              &.edit-btn {
                img {
                  width: 1rem;
                }
              }
              &.delete-btn {
                margin-left: 0.5rem;
                img {
                  width: 1.5625rem;
                }
              }
              &:hover {
                background: #e7eaf1;
              }
            }
          }
        }
      }
    }
  }
}
.check-box-wrapper,
.ant-table-selection,
.ant-table-selection-column {
  .ant-checkbox-wrapper {
    color: #455560;
    margin-right: 10px;
    .ant-checkbox {
      top: auto;
      &.ant-checkbox-checked {
        &::after {
          border-radius: 4px;
        }
        .ant-checkbox-inner {
          border-color: $primary;
          background: $primary url(../images/icons/checked.svg) no-repeat center;
          background-size: cover;
          &::after {
            display: none;
          }
        }
      }
      .ant-checkbox-inner {
        border: 2px solid #d5d5d5;
        border-radius: 4px;
      }
    }
  }
}
.movie-info {
  display: flex;
  align-items: center;
  img {
    width: 3.75rem;
  }
  .movie-details {
    padding-left: 1rem;
    .name {
      font-size: 1rem;
      font-weight: 400;
      color: #0d1734;
      margin-bottom: 6px;
    }
  }
}
.song-info {
  display: flex;
  align-items: center;
  img {
    width: 2.1875rem;
    height: 2.1875rem;
    border-radius: 6px;
  }
  .song-details {
    padding-left: 0.625rem;
    .name {
      font-size: 0.875rem;
      margin-bottom: 4px;
      color: #0d1734;
    }
  }
}
.success-txt {
  color: #27ae60;
}
.warm-txt {
  color: #f2994a;
}
.warning-txt {
  color: #ffb946;
}
.primary-txt {
  color: $primary;
}
.tag-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .tag {
    margin: 3px 6px 3px 0;
  }
}
.tag {
  font-size: 0.75rem;
  padding: 3px 0.625rem;
  border-radius: 2px;
  font-weight: 400;
  color: #0d1734;
  &.movie-tag {
    background: #fff5e6;
  }
  &.trailer-tag {
    background: #ffebf1;
  }
  &.series-tag {
    background: #d3eaff;
  }
  &.song-tag {
    background: #e2e2ff;
  }
  &.redshade {
    background: #ffe6db;
  }
  &.lightblue {
    background: #cbecff;
  }
  &.purpleshade {
    background: #e0d4ff;
  }
  &.greenshade {
    background: #e0d4ff;
  }
}
.selected-item-row {
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 14px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #6d93de;
  background: #f4fbff;
  padding: 0.75rem 1.5625rem;
  border-radius: 3px;
  margin-bottom: 1.25rem;
  .ant-select {
    border-color: #979797;
    margin-right: 10px;
    .ant-select-arrow {
      background-image: url(../images/icons/down-triangle-arrow.svg);
    }
  }
  .selected-label {
    font-size: 0.9375rem;
    color: #000000;
    padding: 0.625rem 1.875rem 0.625rem 0;
    border-right: 1px solid #000;
    margin-right: 1.875rem;
  }
  .close-btn {
    margin-left: auto;
  }
}
.btn {
  display: inline-flex;
  align-items: center;
  font-size: 0.9375rem;
  font-weight: 600;
  height: auto;
  border-radius: 6px;
  img,
  .anticon {
    margin-right: 10px;
  }
  &.ant-btn-sm {
    padding: 0.3125rem 0.9375rem;
    font-size: 13px;
    font-weight: 500;
  }
}
.primary-outline {
  border-color: $primary;
  color: $primary;
  background-color: transparent !important;
  padding: 0.625rem 1.875rem;
  &:hover,
  &:focus {
    border-color: #34aff9;
  }
  &:active {
    border-color: #098edf;
  }
  &:disabled {
    border-color: #c2cfe0;
    color: #c2cfe0;
  }
}
.delete-btn {
  border: 0;
  background: transparent;
  color: #f96e6f;
  outline: none;
  img {
    margin-right: 8px;
  }
}
.close-btn {
  img {
    width: 1rem;
    height: 1rem;
    margin: 0;
  }
}
.outline-btn {
  background: #fff;
  padding: 0.625rem 1.875rem;
  border-radius: 4px;
  border: 1px solid $primary;
  color: $primary;
  font-weight: 600;
  font-size: 0.875rem;
  transition: 0.3s;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(96, 125, 139, 0.3);
  z-index: 9;
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .modal-container {
      width: 100%;
      max-width: 380px;
      background: #fff;
      padding: 25px 0;
      border-radius: 6px;
      .modal-body {
        padding: 25px;
        .content {
          display: flex;
          align-items: center;
          justify-content: center;
          .warning-icon {
            margin-right: 15px;
          }
          p {
            font-size: 0.9375rem;
            color: #000;
          }
        }
      }
      .modal-footer {
        padding: 0 25px;
        .btn-action {
          display: flex;
          justify-content: center;
          .btn {
            margin-left: 10px;
            box-shadow: none;
            padding: 5px 1.25rem;
            font-size: 0.875rem;
            transform: none;
          }
        }
      }
    }
  }
}

.media-modal {
  .ant-drawer-content-wrapper {
    width: 27.5rem !important;
    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      padding: 1.25rem 1.875rem;
    }
  }
  .modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    h3 {
      font-size: 1.375rem;
      color: #0d1734;
      font-weight: 700;
    }
    button {
      font-weight: 600;
      color: #000;
      margin-left: auto;
    }
  }
  .modal-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .search-field {
      width: 100%;
      background: #f2f4fc;
      margin-bottom: 1.875rem;
    }
    .suggestion-list {
      flex: 1;
      overflow: auto;
      ul {
        li {
          padding: 10px 0;
          cursor: pointer;
          &.selected {
            opacity: 0.3;
            pointer-events: none;
          }
          &:hover {
            background: rgba(161, 180, 255, 0.05);
          }
          .movie-info {
            .movie-details {
              p {
                margin: 0 6px 0 0;
              }
            }
          }
        }
      }
      .list-action {
        text-align: center;
      }
    }
  }
}

.form-field-wrapper {
  .multi-form-group {
    display: flex;
    border: 1px solid #d4dee2;
    border-radius: 5px;

    .custom-form-group {
      border: 0;
      border-right: 1px solid #d4dee2;
      border-radius: 0;
      padding: 0.5rem 1rem 0;
      &:last-child {
        border-right: 0;
      }
    }
  }
  .custom-form-group {
    border-bottom: 1px solid #d4dee2;
    .form-label {
      display: flex;
      align-items: center;
      margin-bottom: 0.3125rem;
      label {
        font-size: 0.875rem;
        color: #0d1734;
      }
      .info-icon {
        margin-left: 5px;
      }
    }
    .input-field {
      input {
        min-height: 30px;
      }
      .ant-select-multiple {
        height: auto !important;
        padding: 0 !important;
        .ant-select-selector {
          width: 100%;
        }
      }
      input,
      .ant-select,
      .ant-picker {
        border: 0;
        padding: 0;
        font-size: 0.875rem;
        color: #0d1734;
        box-shadow: none;
        width: 100%;
        font-weight: 400;
        padding: 5px 0;
        height: 40px;
        &::placeholder {
          color: rgba(125, 130, 154, 0.6);
        }
        .ant-select-selector {
          padding: 0 !important;
        }
        .ant-select-arrow {
          width: 8px;
          height: 4px;
          right: 10px;
        }
      }
    }
  }
}
.cast-card-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.875rem;
  .cast-card {
    display: flex;
    background: #f7f7f7;
    border-radius: 4px;
    padding: 0.625rem 0.875rem;
    margin: 0 10px 10px 0;
    .cast-image {
      img {
        width: 2.9375rem;
        height: 2.9375rem;
        border-radius: 4px;
        object-fit: cover;
      }
    }
    .cast-details {
      padding: 0 0.875rem;
      .name {
        font-size: 0.875rem;
        color: #0d1734;
      }
      .cast-tag {
        display: flex;
        flex-wrap: wrap;
        .cast-tag-item {
          padding: 0.375rem 0.75rem;
          border-radius: 11px;
          background: #edeffa;
          color: #6477b1;
          font-size: 0.75rem;
          margin: 5px 10px 0px 0;
        }
      }
    }
  }
}
.required {
  &::after {
    content: "*";
    color: $error-color;
  }
}
.error-msg {
  font-size: 0.875rem;
  color: $error-color;
}
.img-field {
  .image-preview {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    &.hide {
      opacity: 0;
      visibility: hidden;
    }
    img {
      height: 100%;
    }
    .close-btn {
      position: absolute;
      top: 5px;
      right: 5px;
      background: #3f4a6c;
      border: 0;
      border-radius: 4px;
      width: 27px;
      height: 27px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      img {
        width: 10px;
        height: 10px;
      }
    }
  }
  &.poster-uploader {
    .placeholder-img {
      margin: 0 !important;
    }
    p {
      display: block !important;
      margin-top: 10px !important;
    }
    .ant-upload {
      flex-direction: column;
    }
  }
  .ant-upload.ant-upload-select-picture-card {
    position: relative;
    border: 1px solid #c8cfef;
    border-radius: 6px;
    background: #f8faff;
    height: 12.5rem;
    margin: 0;
    width: 100%;
    overflow: hidden;
    .placeholder-img {
      width: 3.5625rem;
      margin-right: 1.25rem;
    }
    p {
      display: inline-block;
      font-size: 14px;
      color: #7d829a;
    }
  }
}
.input-button{
  position: relative;
  height: 100px;
  width : 200px;
  .ant-input{
    width: 70%;
    height: 25px;
    padding-right: 60px;
  }
  .ant-button{
    position: absolute;
    right: 0;
    top : 5px;
  }
}
.outline-checkbox {
  color: #000;
  font-size: 0.9375rem;
  .ant-checkbox {
    top: 0.3125rem;
    .ant-checkbox-inner {
      width: 1.25rem;
      height: 1.25rem;
      transition: none !important;
      border: 2px solid $primary;
      border-radius: 3px;
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: #fff url(../images/icons/primary-check.svg) no-repeat center;
      background-size: 0.75rem;
      &::after {
        display: none;
      }
    }
  }
}
.gray-btn {
  background: #e3e3e3;
  color: #999999;
  &:hover {
    border-color: #e3e3e3;
  }
}
.ant-radio-wrapper {
  color: #333333;
  font-weight: 500;
  font-size: 0.9375rem;
  .ant-radio {
    top: 5px;
    &.ant-radio-checked {
      .ant-radio-inner {
        border-color: $primary;
      }
    }
  }
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-color: #849ba4;
    &::after {
      width: 10px;
      height: 10px;
    }
  }
}
.info-msg {
  font-size: 0.875rem;
  color: #7d829a;
}
.status {
  display: inline-flex;
  align-items: center;
  &::before {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 30px;
    background: currentColor;
    margin-right: 5px;
  }
  &.active {
    color: $success-color;
  }
  &.inactive {
    color: $error-color;
  }
}
.section-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  .section-header {
    padding: 12px;
    background: rgba(255, 255, 255, 0.6);
  }
  .section-body {
    flex: 1;
    overflow: auto;
    background: #fff;
    border: 1px solid #f1f3fb;
    border-width: 1px 0px;
    padding: 1.5625rem 1.875rem;
  }

  .section-footer {
    border-top: 1px solid #cecece;
    padding: 1.5625rem 0 0.3125rem;
    .footer-content {
      display: flex;
      align-items: center;
      .action-btn {
        margin-left: auto;
      }
      p {
        color: #393e58;
        font-size: 0.875rem;
      }
      .btn {
        padding: 0.3125rem 1.25rem;
        font-weight: 700;
        margin-left: 0.625rem;
        transform: none !important;
        font-size: 0.875rem;
        &.gray-btn {
          background: #e4e9f8;
          color: #5f70a8;
          border: 0 !important;
        }
      }
    }
  }
}
.ant-tag {
  padding: 0.25rem 0.625rem;
  border-radius: 4px;
  font-weight: 500;
  color: #333;
  &.ant-tag-cyan {
    color: #04d182;
    background: rgba(4, 209, 130, 0.1);
    border-color: transparent;
    font-weight: 500;
    // background: $success-color;
    // border-color: $success-color;
    // color: #fff;
  }
  &.ant-tag-red {
    color: #de4436;
    background: rgba(222, 68, 54, 0.05);
    border-color: transparent;
    font-weight: 500;
}
&.ant-tag-blue {
  color: #3170e5;
  background: rgba(59, 124, 214, 0.05);
  border-color: transparent;
  font-weight: 500;
}
&.ant-tag-magenta {
  color: #e75480;
  background: rgb(255,228,225);
  border-color: transparent;
  font-weight: 500;
}
&.ant-tag-yellow {
  color: #ffc40c;
  background: rgb(255,255,224);
  border-color: transparent;
  font-weight: 500;
}
&.ant-tag-gold {
  color: #da9100;
  background: rgb(245,245,220);
  border-color: transparent;
  font-weight: 500;
}
}
.link {
  color: $primary;
  &:hover {
    text-decoration: underline;
  }
}
.rating {
  border-radius: 15px;
  border: 1px solid currentColor;
  font-size: 14px;
  color: currentColor;
  padding: 2px 12px;
  &.very-good {
    color: $success-color;
  }
  &.good {
    color: $good-color;
  }
  &.average {
    color: $average-color;
  }
}
.info-image-container
 {
  vertical-align: middle;
  display: inline-block;
  border-radius: '25px';
  padding: '4px 15px 4px';
  

}
.info-container
 {
    display: inline-block;
    margin-bottom: 40px;
   
}

.info-list
 {
  display: inline-block;
  vertical-align: middle;
 ul {
  padding: 0 0 0 20px;
  margin: 0;
  list-style: none;

  label{
    font-size: 0.9rem;
  }
  p{
    font-size: 0.9rem;
    color: #979797;
  }
}
}

.carer-about-details{
  font-size: 0.9rem;
  margin-bottom: 20px;
  b{
    margin-bottom: 50px;
  }
}

.tasks-data{
  display:inline-block;
   flex-direction:'row'; 
   margin-left:20px;
   margin-top:10px;
}
.tasks-name-data{
  border: 1px solid #e4eaf3;
  border-radius: 5px;
  padding: 4px 15px 4px;
  width:290px;

}
.input-audio-details{
  border: 1px solid #e4eaf3;
  border-radius: 5px;
  padding: 4px 15px 4px;
  width:400px;

}

.tasks-language-data{
  width:90px;
  // background-color: #cecece;
  border-radius: 6px;
  border: 1px gainsboro solid;
  padding: 4px 15px 4px;
}
.current-status {
  display: flex;
  align-items: end;
  color: currentColor;
  &.publish {
    color: $success-color;
  }
  &.un-publish {
    color: $unpublished-color;
  }
  img {
    margin-right: 7px;
  }
}
.transcode-status {
  display: flex;
  align-items: center;
  img {
    margin-right: 7px;
    &.processing-icon {
      animation: rotate 0.8s ease-in-out infinite both;
    }
  }
}
.ant-table-pagination.ant-pagination {
  padding: 0 15px;
}
.upload-btn {
  margin-right: 10px;
  .btn {
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 4px;
    height: 36px;
    padding: 0px 1.125rem;
  }
}

.media-carousel-body {
  padding: 0 30px;
  flex: 1;
  overflow: hidden;
  & > div {
      display: flex;
      align-items: center;
      height: 100%;
      overflow: auto;

      .carousel-slider {
          .control-arrow {
              width: 46px;
              height: 46px;
              bottom: auto;
              top: 50%;
              opacity: 1;
              background-color: rgba(0, 0, 0, 0.35);
              background-size: 20px;
              background-repeat: no-repeat;
              background-position: center;
              border-radius: 50%;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
              &.control-prev {
                  background-image: url(../images/left-arrow.svg);
              }
              &.control-next {
                  background-image: url(../images/right-arrow.svg);
              }
              &::before {
                  display: none;
              }
              &.control-disabled {
                  opacity: 0.5 !important;
                  display: block;
              }
          }
      }
      .carousel {
          height: 100%;

          .slider-wrapper {
              height: 100% !important;
              width: 75%;
              margin: 0 auto;
              .slider {
                  height: 100%;

                  .slide {
                      height: 100%;
                      background: transparent;
                      padding: 0 10px;
                      .carousel-items {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          height: 100%;
                          img {
                              max-width: 100%;
                              max-height: 85%;
                              width: auto;
                          }
                          .video-js {
                              max-width: 100%;
                              max-height: 85%;
                              width: 500px;
                              height: 300px;
                          }
                          p {
                              margin: 10px 0 0;
                              color: rgba(0, 0, 0, 0.6);
                              font-size: 13px;
                          }
                      }
                  }
              }
          }

          .thumbs-wrapper {
              display: flex;
              margin-bottom: 8px;
              .control-arrow {
                  display: none;
              }
              .thumbs {
                  margin: 0;
                  padding: 0;
                  .thumb {
                      padding: 0px;
                      height: 80px;
                      margin: 0;
                      img {
                          height: 100%;
                          object-fit: cover;
                      }
                  }
              }
          }
      }
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

.custom-select.ant-select-selector {
  border: none !important;
}
.add-page {
  width: 80%;
  background: #fff;
  border-radius: 8px;
  .add-form-header {
    padding: 20px 25px;
    border-bottom: 1px solid #e4eaf3;
    h3 {
      font-size: 1rem;
      font-weight: 600;

      color: #404041;
    }
  }
  .add-form-container {
    padding: 20px 25px;
    .ant-input {
      border: 1px solid #e1e8ed;
      border-radius: 6px;
      padding: 7px 11px;
    }
    .ant-select {
      border: 1px solid #e1e8ed;
      border-radius: 6px;
    }
    .ant-form-item {
      margin-bottom: 15px;
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: none;
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }
  .add-form-action {
    display: flex;
    justify-content: flex-end;
    padding: 0px 25px 20px 25px;
    .cancel-btn {
      padding: 6px 22px;
      height: auto;
      border-radius: 6px;
      font-size: 15px;
    }
    .submit-btn {
      padding: 6px 22px;
      height: auto;
      border-radius: 6px;
      font-size: 15px;
      margin-left: 15px;
    }
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.hidden {
  display: none;
}
.foo {
  display: none;
  opacity: 0;
  animation: fade-out 1s 1;
}
.foo.fade-in {
  display: block;
  opacity: 1;
  animation: fade-in 0.5s 1;
}
.chip-input {
  border: 0 !important;
  padding-left: 0 !important;
  span {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    background: #f2f4fc;
    color: #0d175a;
    border-radius: 4px;
    font-size: 13px;
    &::after {
      display: inline-block;
      content: "";
      margin-left: 10px;
      width: 8px;
      height: 8px;
      background: url(../images/icons/cancel.svg);
      background-size: cover;
    }
  }
}
.img-container {
  float: left;
  width: 20%;
  padding: 12px;
  overflow: hidden;
  transition: 1s ease;
  justify-content: center;
  .div {
    margin-left: 1em;
    color: #aaa;
    display: block;
    text-align: center;
    font-size: 5px;
  }
  &:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 0.5s ease;
  }
}

.clearfix {
  content: "";
  clear: both;
  display: inline-block;
  align-items: center;
  text-align: center;
  margin: auto;
}

.ant-select-multiple .ant-select-selection-item {
  display: inline-flex;
  align-items: center;
  padding: 3px 10px !important;
  background: #f2f4fc;
  color: #0d175a;
  height: auto;
  border-radius: 4px;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  margin-left: 8px;
  width: 8px;
  height: 8px;
  background: url(../images/icons/cancel.svg);
  background-size: cover;
  vertical-align: inherit;
  svg {
    display: none;
  }
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-stream-card {
  background-color: #d5dbdb;
  width: 100%;
}
.video-stream {
  margin-top: 50px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 1.5625rem 1.875rem;
  margin-top: auto;
  span,
  a {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.4);
  }
  a {
    margin-left: 1.5625rem;
  }
}
.ant-menu-inline-collapsed-tooltip {
  .ant-tooltip-arrow-content {
    background-color: #fff;
  }
  .ant-tooltip-inner {
    background-color: #fff;
    a {
      color: #1c1c1c;
      font-size: 1rem;
      span {
        margin-left: 10px;
      }
    }
  }
}
.ticket-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 1rem;
  border-bottom: 1px solid #ebeff3;

  .ant-image {
    width: 4.6875rem;
    height: 4.6875rem;
  }
  .ticket-content {
    h3 {
      font: 1rem 'SFProText-Semibold';
      color: #12344d;
      margin-bottom: 0.3125rem;
    }
    p {
      color: #12344d;
      font: 0.75rem 'SFProText-Regular';
    }
  }
}

.chat-container {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .comment-list {
    flex: 1;
    overflow: auto;
    .ant-list-items {
      max-width: 46.875rem;
      padding: 0.625rem 1rem;
      .comment-listing {
        padding: 1.25rem 1rem;
        border-radius: 12px;
        margin-bottom: 20px;
        &:last-child {
          margin: 0;
        }
        .comment-header {
          display: flex;
          align-items: center;
          margin-bottom: 1.25rem;
          img {
            width: 2rem;
            height: 2rem;
            border-radius: 6px;
          }
          .comment-label {
            flex: 1;
            padding: 0 1rem;
            h2 {
              font: 0.875rem SFProText-Semibold;
              margin-bottom: 4px;
              color: #12344d;
            }
            p {
              font: italic 0.75rem SFProText-Regular;
              margin: 0;
              color: #475867;
            }
          }
          .comment-action {
            button {
              width: 2rem;
              height: 2rem;
              padding: 0;
              border: 1px solid #fddbb5;
              background: #fff;
              border-left-width: 0px;
              cursor: pointer;
              transition: 0.3s;
              &:hover {
                box-shadow: 0px 0px 1px #fac386;
              }
              &:first-child {
                border-left-width: 1px;
                border-radius: 6px 0px 0px 6px;
              }
              &:last-child {
                border-radius: 0px 6px 6px 0px;
              }
              img {
                height: 13px;
                width: auto;
                border-radius: 0;
              }
            }
          }
        }
        .comment-body {
          .ant-tag {
            margin-bottom: 5px;
          }
          p {
            font: 0.875rem 'SFProText-Regular';
            color: #12344d;
            margin: 5px 0;
          }
        }
      }
    }
  }
  form {
    display: flex;
    align-items: baseline;
    padding: 0.625rem 1rem;
    .textbox-comment {
      border: 1px solid #ebeff3;
      border-radius: 30px;
      width: 100%;
      padding: 0.9375rem 1.25rem;
      font: 0.875rem 'SFProText-Regular';
      outline: none;
    }
    .ant-form-item {
      margin: 0;
      flex: 1;
      padding-right: 1.875rem;
    }
    .ant-select {
      width: 9.375rem;
      font: 0.875rem SFProText-Semibold;
      .ant-select-selector {
        height: auto;
        border-radius: 4px;
        border-color: #cfd7df !important;
        color: #92a2b1;
        box-shadow: 0px 1px 2px 0px rgba(24, 50, 71, 0.051) inset !important;
        .ant-select-selection-item {
          line-height: 38px;
        }
      }
      .ant-select-arrow {
        width: 10px;
        height: 6px;
      }
    }
  }
}

.comment-list {
  flex: 1;
  overflow: auto;
  .ant-list-items {
    max-width: 46.875rem;
    padding: 0.625rem 1rem;
    .comment-listing {
      padding: 1.25rem 1rem;
      border-radius: 12px;
      margin-bottom: 20px;
      &:last-child {
        margin: 0;
      }
      .comment-header {
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        img {
          width: 2rem;
          height: 2rem;
          border-radius: 6px;
        }
        .comment-label {
          flex: 1;
          padding: 0 1rem;
          h2 {
            font: 0.875rem SFProText-Semibold;
            margin-bottom: 4px;
            color: #12344d;
          }
          p {
            font: italic 0.75rem SFProText-Regular;
            margin: 0;
            color: #475867;
          }
        }
        .comment-action {
          button {
            width: 2rem;
            height: 2rem;
            padding: 0;
            border: 1px solid #fddbb5;
            background: #fff;
            border-left-width: 0px;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
              box-shadow: 0px 0px 1px #fac386;
            }
            &:first-child {
              border-left-width: 1px;
              border-radius: 6px 0px 0px 6px;
            }
            &:last-child {
              border-radius: 0px 6px 6px 0px;
            }
            img {
              height: 13px;
              width: auto;
              border-radius: 0;
            }
          }
        }
      }
      .comment-body {
        .ant-tag {
          margin-bottom: 5px;
        }
        p {
          font: 0.875rem 'SFProText-Regular';
          color: #12344d;
          margin: 5px 0;
        }
      }
    }
  }
}
.ant-menu-submenu-popup {
  .ant-menu-item {
    &.ant-menu-item-selected {
      background: rgba(0, 0, 0, 0.05);
      a {
        color: #27ae60;
      }
    }
    a {
      color: #1c1c1c;
      font-size: 1rem;
      &:hover {
        color: #27ae60;
      }
    }
  }
}

.bookings-view-container {
  display: flex;
  align-items: center;
  
}

.bookings-view-image {
  display: inline-block;
  margin-right: 10px;
  width: 50px;
 
}

.bookings-view-text {
  display: inline-block;
}
.real-time-contents-page {
  .chart-card {
    background: #ffffff;
    border-radius: 10px;
    .chart-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.875rem 1.5625rem;
      h4 {
        font-weight: 400;
        font-size: 1rem;
        color: #1c1c1c;
      }
      span {
        font-weight: 600;
        font-size: 16px;
        color: #1c1c1c;
      }
    }
  }
}

.form-add {
  display: flex;
  align-items: baseline;
  padding: 0.625rem 1rem;
  .textbox-comment {
    border: 1px solid #ebeff3;
    border-radius: 30px;
    width: 100%;
    padding: 0.9375rem 1.25rem;
    font: 0.875rem 'SFProText-Regular';
    outline: none;
  }
  .ant-form-item {
    margin: 0;
    flex: 1;
    padding-right: 1.875rem;
  }
  .ant-select {
    width: 9.375rem;
    font: 0.875rem SFProText-Semibold;
    .ant-select-selector {
      height: auto;
      border-radius: 4px;
      border-color: #cfd7df !important;
      color: #92a2b1;
      box-shadow: 0px 1px 2px 0px rgba(24, 50, 71, 0.051) inset !important;
      .ant-select-selection-item {
        line-height: 38px;
      }
    }
    .ant-select-arrow {
      width: 10px;
      height: 6px;
    }
  }
}

@import "./subscription.scss";
@import "./auth.scss";
@import "./dashboard.scss";
@import "./video-management.scss";
@import "./video-detail.scss";
@import "./responsive.scss";
@import "./new.scss";
@import "./table.scss";
@import "./modal.scss";
@import "./dashboard.css";
