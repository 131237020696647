.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f2f4fc;
  padding: 10px;
  .login-page-container {
    background: #ffffff;
    box-shadow: 0px 23px 99px #26a69a24;
    border-radius: 20px;
    width: 100%;
    max-width: 480px;
    padding: 45px 55px;
    .logo {
      text-align: center;
      margin-bottom: 30px;
      img {
        width: 100px;
      }
    }
    article {
      h2 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      p {
        color: #6b8091;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .login-form-container {
      margin-top: 20px;
      .login-action {
        margin-bottom: 20px;
        text-align: right;
      }
      .submit-btn {
        border-radius: 30px;
        padding: 12px 10px;
        height: auto;
        font-size: 15px;
        font-weight: 500;
      }
      .ant-input-affix-wrapper {
        padding: 12px 16px;
        border-radius: 7px;
      }
      .ant-form-item-label {
        label {
          &:before {
            content: none;
          }
          &:after {
            display: inline-block;
            margin-left: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: "*";
          }
        }
      }
    }
  }
}
