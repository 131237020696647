.ant-table {
    font-size: 14px!important;
    line-height: 1.5 !important;
    color: #455560 ;
    border-radius: 0.625rem; 

    table {
        border-radius: 0.625rem 0.625rem 0 0;
    }

    &.ant-table-bordered {

        .ant-table-title {
            border: 1px solid #ededed ;
            border-bottom: 0;
        }
        .ant-table-footer {
            border: 1px solid #ededed ;
            margin-top: -2px;
            border-top: 0;
        }

        .ant-table-container {
            border: 1px solid #edf2f9;
            border-right: 0;
            border-bottom: 0;
        }

        thead > tr > th, 
        tbody > tr > td, 
        tfoot > tr > th, 
        tfoot > tr > td {
            border-right: 1px solid #edf2f9;
        }

    } 

    // .ant-table-content {
    //     @include screen-mobile {
    //         overflow-x: auto;
    //     }
    // }
}


.ant-table-thead {
    > tr {

        > th {
            background: #ffffff ;
            text-align: left;
            color: #1a3353;
            font-weight: bold;
            border-bottom: 1px solid #ededed ;
            padding:  0.9375rem;

            .ant-table-filter-icon,
            .anticon-filter {
                color: #455560 ;
            }

            &.ant-table-column-sort {
                background: #f1f5f6;
            }

            
            &.ant-table-column-has-actions {
                &.ant-table-column-has-sorters {

                    &:hover {
                        background: #f1f5f6;

                        .anticon-filter,
                        .ant-table-filter-icon {
                            background: #f1f5f6;

                            &:hover {
                                background: darken(#f1f5f6, 5%);
                            }
                        }
                    }
                }
            }

            .ant-table-header-column {
                .ant-table-column-sorters {

                    &:hover {
                        &:before {
                            background:#ffffff ;
                        }
                    }
                }
            }
        }

        &:first-child {
            >th{
                &:first-child {
                    border-top-left-radius: 0.625rem; 
                }

                &:last-child {
                    border-top-right-radius: 0.625rem; 
                }
            }
        }
    }
}

.ant-table-tbody {

    > tr {

        > td {
            padding:  0.9375rem;
            border-bottom: 1px solid #ededed ;

            // @include screen-mobile {
            //     white-space: nowrap;
            // }

            &.ant-table-column-sort {
                background: #fcfcfc ;
            }
        }

        &.ant-table-row {

            &:hover {
                > td {
                    background: #fcfcfc ; 
                }
            }
        }

        &.ant-table-row-selected  {
            > td {
                background:  rgba(#3e82f7 , 0.1) !important; 
            }
        }
    }
}  

.ant-table-container {
    border-top-left-radius: 0; 
    border-top-right-radius: 0; 

    table {
        > thead {
            > tr:first-child th:first-child {
                border-top-left-radius: 0; 
            }
        }
    }
}

tbody > tr.ant-table-placeholder:hover > td {
    background: #ffffff  !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: #3e82f7 ;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #fcfcfc;
}

.ant-table-column-sorter {
    margin-left: 10px;
    font-weight: 400;
    color: #455560 ;
}


.ant-table-bordered {

    .ant-table-body,
    .ant-table-header {

        >table {
            border: 0px;
            border-bottom: 0px;
            border-right: 0px;
        }
    }

    .ant-table-tbody {

        > tr {
            > td {
                border-right: 1px solid #ededed ;
            }
        }
    }

    .ant-table-thead {

        > tr {
            > th {
                border-right: 1px solid #ededed ;
            }
        }
    }

    .ant-table-fixed-left,
    .ant-table-fixed-right {

        table {
            border: 0px;
            border-bottom: 0px;
            border-right: 0px;
        }
    }
}

.ant-table-title {
    padding:  0.9375rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0; 
}

.ant-table-footer {
    padding:  0.9375rem;
    background: transparent;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; 
    border-top: 0;
}

.ant-table-row-expand-icon {
    width: 1.0625rem;
    height: 1.0625rem;
    line-height: 1.0625rem - 0.1875rem;
    border: 1px solid #ededed ;
    background:#ffffff;
}

tr.ant-table-expanded-row {
    background-color: transparent;

    &:hover {
        background-color: #fcfcfc;
    }
}

.ant-table-selection-select-all-custom {
    margin-right: 10px !important;
}

.ant-table-fixed-header {
    .ant-table-thead {
        > tr {
    
            > th {
                background: #f7f7f8;
            }
        }
    }                   
}

.editable-cell {
    position: relative;
  }

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
    padding-right: 24px;
}

.editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
}

.editable-cell-icon {
    line-height: 20px;
    display: none;
}

.editable-cell-icon-check {
    line-height: 38px;
}

.editable-cell:hover .editable-cell-icon {
    display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
    color: #3e82f7;
}

.no-border-last {
    .ant-table {
        .ant-table-tbody {

            > tr {

                &:last-child {
                    > td {
                        border-bottom: 0px;
                    }
                }
            }
        }  
    }  
}

.ant-table-pagination {
    &.ant-pagination {
        margin: 1rem 0.625rem;
    }
}
